import { createApp } from "vue";
import Buefy from "buefy";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);

const app = createApp(App);

createApp(App).use(store).use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");